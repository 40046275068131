<template>
	<div>
		<h1>View Beer from tbl-xls component</h1>
		<hr />
		<tbl-xls-viewer
			:items="items"
			:aStandardSelFields="aStandardSelFields"
			subjectName="beers"
		/>
	</div>
</template>

<script>
import TblXlsViewer from "@/components/TblXlsViewer";
import { getBeers} from "@/api/bier.js";

export default {
	//name: "",
	components: {
		TblXlsViewer,
	},

	data: () => ({
		items: [],
		bLoaded: false,
		aStandardSelFields: ["naam", "brouwer", "gisting"],
	}),

	created() {
        getBeers().then((apiData) => {
            console.log("getBeers() apiData:", apiData);
			if (apiData.data.bSuccess) {
                    this.bLoaded = true;
                    this.items = apiData.data.data;
                } else {
                    this.$root.$emit("showSnackbar", {
                        text: "2) "+apiData.data.sMessage,
                        type: "error",
                        time: 7000,
                    });
                }
            return null //response.data.data
        });
	},
};
</script>
